@tailwind base;
@tailwind components;
@tailwind utilities;

.react-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: 100vw;
  height: 100vh;
}

.splash-modal {
  z-index: 999999;
  background-color: rgba(3, 3, 3, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  padding: 3rem;
  margin-inline: auto;
  width: fit-content;
}

.splash-modal p {
  z-index: 9999999;
  background-color: #f3f3f3;
  font-size: 1.125rem;
  padding: 2rem;
  margin: auto;
}
